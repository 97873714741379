.headerDataWrapper {
    display: flex;
    align-items: baseline;
    margin: 2px 0;

    &:first-child {
            font-weight: 400;
            margin-right: 0.25rem;
            white-space: nowrap;
        }
}

.deleteIcon {
    position: absolute;
    right: 21px;
    bottom: 14px;
    cursor: pointer;
}


.widgetHeaderRow{
    position: relative;
    .widgetHeaderCol{
        width: 33.33333333%;
    }
    .segmentHeaderCol{
        width: 66.66666667%;
    }

    .accorionIcon{
        position: absolute;
        top: 19px;
        right: 21px;
    }

    &.AccordionOpen{
        border-radius: 8px 8px 0 0;
    }
}


.widgetHeaderTitle {
    font-weight: 400;
    min-width: fit-content;
}
.widgetTitle {
    padding-right: 1.5rem;
    gap: 10px;
}

.tooltipWithBg {
    background: #E7E8E8;
    padding: 0.7px 0.5rem;
    border-radius: 20px;
    color: #000;
    text-decoration: none;

    &:hover {
        color: #551A8B; 
    }
}

.segmentPara {
    background: #E7E8E8; padding: 0.7px 0.5rem; border-radius: 20px;
}

.tooltipLink {
    color: #000;
    text-decoration: none;

    &:hover {
        color: #551A8B; 
    }
}